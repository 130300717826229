
import AccordionWidget from '../AccordionWidget/AccordionWidget';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import './InteractionDetails.css'
export default function InteractionDetails(props) {
    const interaction = props.interaction;
    return (
        <AccordionWidget title="Interaction details">
            <div className="interaction-details">
                <Typography>
                    <ul>
                        <p>
                            <b>Number:</b> {interaction[1]}
                        </p>
                        <p>
                            <b>View:</b> {interaction[2]}
                        </p>
                        <p>
                            <b>Gesture:</b> {interaction[3]}
                        </p>
                        <p>
                            <b>Triggered control:</b> {interaction[4]}
                        </p>
                        <p>
                            <b>Time from start:</b> {interaction[5]} <i>ms</i>
                        </p>
                    </ul>
                </Typography>
                <div>
                <Button className="spaceme" variant="contained"
                    color="default"
                    size="small" onClick={() => { if (props.onMove) props.onMove(-1); }}>prev</Button>
                <Button className="spaceme" variant="contained"
                    color="default"
                    size="small" onClick={() => { if (props.onMove) props.onMove(+1); }}>next</Button>
            </div>
            </div>
           
        </AccordionWidget>
    );
}