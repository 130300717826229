
import AccordionWidget from '../AccordionWidget/AccordionWidget';
import Typography from '@material-ui/core/Typography';
export default function Session(props) {

    const time = new Date(props.session.mints).toLocaleString();
    const session = props.session;
    return (
        <AccordionWidget title="Session details">
            <Typography>
                <ul>
                    <p>
                        <b>Time:</b> {time}
                    </p>
                    <p>
                        <b>Application Name:</b> {session.an}
                    </p>
                    <p>
                        <b>Views:</b> {session.transitions}
                    </p>
                    <p>
                        <b>Seconds:</b> {session.seconds}
                    </p>
                    <p>
                        <b>Interactions:</b> {session.interactions}
                    </p>
                    <p>
                        <b>Language</b> {session.la}
                    </p>
                    <p>
                        <b>Screen Resolution:</b> {session.sr}
                    </p>
                    <p>
                        <b>App version:</b> {session.av}
                    </p>
                    <p>
                        <b>Device:</b> {session.d}
                    </p>
                    <p> <b>Session ID:</b> {session.si}</p>
                    {session.hasCrash ?
                        <p>
                            <b>Crash</b>
                        </p> : null}
                    {session.hasRequests ?
                        <p>
                            <b>Requests</b>
                        </p> : null}
                    {session.hasLabels ?
                        <p>
                            <b>Requests</b>
                        </p> : null}
                </ul>
            </Typography>
        </AccordionWidget>
    );
}