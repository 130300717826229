import React, { useState } from "react";
import {
  Grid
} from "@material-ui/core";


import { useTheme } from "@material-ui/styles";



import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget/Widget";

import JobsErrored from '../../samurai/JobsErrored/JobsErrored';
export default function Developer(props) {
  return (
    <>
      <PageTitle title="Job Processing" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
       
          
            <JobsErrored></JobsErrored>
        
        </Grid>
      </Grid>
    </>
  );
}

// #######################################################################
