export default function Crash() {
    return (<code>
      10
      20
      30
      40
      50
      0
      10
      20
      LoginA…
      ProductTourClienteActivity_
      MainActivity_
      TransferAccountAct…
      crash
      0 java.lang.NullPointerException: Attempt to invoke virtual method 'void androidx.fragment.app.FragmentActivity.runOnUiThread(java.lang.Runnable)' on a null object reference 1 at com.sfy.fetalbodyguard.C_Home.PAC_C2_MedicionesFragment$3.onSuccess(PAC_C2_MedicionesFragment.java:102) 2 at com.sfy.fetalbodyguard.Utils.HttpHelper$4.onSuccess(HttpHelper.java:315) 3 at com.sfy.fetalbodyguard.Utils.HttpHelper$1.onSuccess(HttpHelper.java:146) 4 at com.sfy.framework.HttpUtil$3.onResponse(HttpUtil.java:273) 5 at okhttp3.RealCall$AsyncCall.execute(RealCall.java:174) 6 at okhttp3.internal.NamedRunnable.run(NamedRunnable.java:32) 7 at java.util.concurrent.ThreadPoolExecutor.runWorker(ThreadPoolExecutor.java:1167) 8 at java.util.concurrent.ThreadPoolExecutor$Worker.run(ThreadPoolExecutor.java:641) 9 at java.lang.Thread.run(Thread.java:798)
  </code>)
  }