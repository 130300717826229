import axios from 'axios';
import listToArray from '../ListToArray';
import getBaseUrl from '../ConfigMe';

const url = getBaseUrl()


const token = localStorage.getItem('id_token');

const getFromServer = async () => {
    var axreturn= await axios.get(url,{ headers: { 'obsly-token': token } });
    console.log("in getFromServer", axreturn);
    return axreturn;
}


export default getFromServer;



