import { useImperativeHandle, forwardRef, useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import interactions_array from './InteractionsData';
import { Button } from '@material-ui/core';

const Interactions = forwardRef((props, ref) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowSelected, setRowSelected] = useState(null);
  const columns = [
    {
      name: 'ei', label: 'Ei', options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'i', label: 'I#', options: {
        filter: false,
        sort: true,
      }
    }, { name: "v", label: 'View' },
    { name: "g", label: 'Gesture' }];

  useImperativeHandle(ref, () => ({
    moveChild(quantity) {
      let newValue = Number(rowSelected[0]) + quantity;
      if (newValue < 0) newValue = data.length - 1
      if (newValue >= data.length) newValue = 0
      console.log("in interactions , newRow=", newValue);
      setRowSelected([newValue]);
      if (props.onSelection) {
        props.onSelection(data[newValue]);
      }
    },
    clearSelected() {
      setRowSelected(null);
    }
  }));
  const options = {
    selectableRows: 'single',
    print: false,
    download: false,
    tableBodyHeight: '53vh',
    filterType: 'dropdown',
    elevation: 2,
    rowsSelected: rowSelected,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return <Button onClick={() => { setSelectedRows([]) }}>Clear</Button>
    },
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      console.log(rowsSelectedData, allRows, rowsSelected);
      setRowSelected(rowsSelected);
      if (props.onSelection) {
        props.onSelection(data[rowsSelected]);
      }
    },
  };

  useEffect(() => {
    const getData = async () => {
      var intermediate = await interactions_array(props.session);
      setData(intermediate);
      setLoading(false);
    };
    getData();
  }, [props.session]);
  if (props.session == null) {
    return (<p>No session selected</p>);
  }

  return (
    <>
      { loading ? <h2>Loading</h2> :
        <MUIDataTable
          title={"Interactions"}
          data={data}
          columns={columns}
          options={options}
        />
      }
    </>
  );
});

export default Interactions;
