function distance(p1,p2) {
    var a = p1.x - p2.x;
    var b = p1.y - p2.y;
    var c = Math.sqrt( a*a + b*b );
    return c;
  }
  
  function interpolate(a, b) // points A and B, frac between 0 and 1
  {
       var d=distance(a,b);
      var parts=Math.min(d/10,20)
      var frac=d/parts;
      console.log("distance",d,"PARTS",parts,"frac",frac)
      if(frac>1) frac=1;
      frac=frac/parts;
      var points=[a]
      for(var part=1;part<parts;part++){
        var nx = Math.round(a.x+(b.x-a.x)*(frac*part));
        var ny = Math.round(a.y+(b.y-a.y)*(frac*part));
        points.push({x:nx,y:ny});
      }
      points.push(b);
      return points;
  }

  export default  interpolate;