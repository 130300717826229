import './Interaction.css';
import { useRef, useState } from 'react';
import { Grid, Button, Toolbar } from '@material-ui/core';
async function isUrlFound(url) {
  try {
    const response = await fetch(url, {
      method: 'HEAD',
      cache: 'no-cache'
    });

    return response.status === 200;

  } catch (error) {
    // console.log(error);
    return false;
  }
}

function Interx(props) {
  let ei;
  if (props.interaction)
    ei = props.interaction[0];

  const urlImage = `https://s3.eu-west-3.amazonaws.com/samuraiimages/${ei}.png`;
  const urlImageUX = `https://s3.eu-west-3.amazonaws.com/samuraiimages/${ei}_ux.png`;
  const urlVideo = `https://samuraiimages.s3.eu-west-3.amazonaws.com/videos/${props.session}.mp4`;

  const [videoExists, setVideoExists] = useState(false);

  setTimeout(async () => {
    setVideoExists(await isUrlFound(urlVideo));
  }, 100);

  let videoElement = useRef(null);

  // exemple de moviment de video
  setTimeout(() => {
    if (videoElement.current != null)
      videoElement.current.currentTime = 1000;
  }, 5000);

  if (props.interaction == null) {
    return (<p>No interaction selected</p>);
  }
  let videoBlock;
  if (videoExists) {
    videoBlock = <video className='background videofix' ref={videoElement} src={urlVideo}></video>
  }
  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={6}>
          <p className="centered">User interface</p>
          <img alt="background" className='background' src={urlImage}></img>
        </Grid>
        <Grid item xs={6}>
          <p className="centered">Interaction</p>
          <img alt="interaction" className='background' src={urlImageUX}></img>
        </Grid>
        {videoBlock}

      </Grid>
      <div className="centered">
      
          <div>
            <Button className="spaceme" variant="contained"
              color="default"
              size="small" onClick={() => { if (props.onMove) props.onMove(-1); }}>prev</Button>
            <Button className="spaceme" variant="contained"
              color="default"
              size="small" onClick={() => { if (props.onMove) props.onMove(+1); }}>next</Button>
          </div>
        

      </div>
    </>
  );
}

export default Interx;