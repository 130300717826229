import React, { useRef, useState } from "react";
import {
  Grid,
  Button
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from './styles';
import AccordionWidget from '../../samurai/AccordionWidget/AccordionWidget';
// components


import PageTitle from "../../components/PageTitle/PageTitle";
import InteractionDetails from '../../samurai/InteractionDetails/InteractionDetails';
import Session from '../../samurai/Session/Session';
import Sessions from '../../samurai/Sessions/Sessions';
import TimeLine from '../../samurai/TimeLine/TimeLine';
import FlowMap from '../../samurai/FlowMap/FlowMap';
import Widget from "../../components/Widget/Widget";
import Interactions from '../../samurai/Interactions/Interactions';
import Interaction from '../../samurai/Interaction/Interaction';
import Crash from '../developer/Crash';
import Requests from '../developer/Requests';
import Tags from '../developer/Tags';
export default function SessionDivided(props) {

  const onSessionSelectionChanged = (elm) => {
    if (elm) {
      setNumCols(4);
      setSession(elm['si']);
      setSessionDetails(elm);
      setInteraction(null);
      if(childInteractionsRef.current) {
        childInteractionsRef.current.clearSelected();
      }
    } else {
      setNumCols(12);
      setSession(null);
      if(childInteractionsRef.current) {
        childInteractionsRef.current.clearSelected();
      }
      setInteraction(null);
    }
  };
  const onInteractionSelectionChanged = (elm) => {
    if (elm) {
      setInteraction(elm);
    } else {
      setInteraction(null);
    }
  };

  const onMoveInteraction = (pos) => {
    console.log("in page move interaction", pos)
    childInteractionsRef.current.moveChild(pos);
  }

  const [session, setSession] = useState(null);
  const [sessionDetails, setSessionDetails] = useState(null);
  const [interaction, setInteraction] = useState(null);
  // local
  const childInteractionsRef = useRef();
  const [numCols, setNumCols] = useState(12);
  let center;



  return (
    <>
      <PageTitle title="Sessions" />

      <Grid container spacing={2}>

        <Grid item xs={numCols}>
          <Sessions onSelection={onSessionSelectionChanged} />
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={1}>
            {session ?
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={12}>
                  <Session session={sessionDetails}></Session>
                </Grid>
                <Grid item xs={12}>
                  <AccordionWidget title="Timeline">
                    <TimeLine session={session}></TimeLine>
                  </AccordionWidget>
                </Grid>
                {sessionDetails.hasCrash ? <Grid item xs={12}><AccordionWidget title="Crash"><Crash></Crash></AccordionWidget></Grid> : null}
                {sessionDetails.hasRequest ? <Grid item xs={12}><AccordionWidget title="Requests"><Requests></Requests></AccordionWidget></Grid> : null}
                {sessionDetails.hasTags ? <Grid item xs={12}><AccordionWidget title="Tags"><Tags></Tags></AccordionWidget></Grid> : null}
                <Grid item xs={12}>
                  <AccordionWidget title="Flow map" expanded={false}>
                    <FlowMap session={session} />
                  </AccordionWidget>
                </Grid>
                <Grid item xs={12}>
                  <Interactions session={session} ref={childInteractionsRef}
                    onSelection={onInteractionSelectionChanged}
                  ></Interactions>

                </Grid>
              </Grid> : null}


          </Grid>
        </Grid>

        {interaction ?
          <Grid item xs={4}>
            <InteractionDetails interaction={interaction} onMove={onMoveInteraction}></InteractionDetails>
            <Widget title={`${interaction[1]}/${Number(sessionDetails['interactions']) + 1}. ${interaction[2]}`} >
              <Interaction interaction={interaction}
                session={session}
                onMove={onMoveInteraction}
              ></Interaction>
            </Widget>

          </Grid> : null}
      </Grid>
    </>
  );
}

// #######################################################################
