import axios from 'axios';
import getBaseUrl from '../ConfigMe';


const host = getBaseUrl()
const url = host+'/allinteractions';

const token = localStorage.getItem('id_token');

const getFromServer = async () => {
    var axreturn= await axios.get(url,{ headers: { 'obsly-token': token } });
    console.log("in getFromServer",axreturn);
    return axreturn;
}

export default getFromServer;