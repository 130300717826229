import axios from 'axios';
import listToArray from '../ListToArray';
import getBaseUrl from '../ConfigMe';

const host = getBaseUrl();
const url = host + '/interactionsbysi';
const token = localStorage.getItem('id_token');

const getFromServer = async (session) => {
    var axreturn = await axios.get(url + "/" + session, { headers: { 'obsly-token': token } });
    console.log("in getFromServer", axreturn);
    return axreturn;
}



const interactions_array = async (session) => {
    if (session != null && session != undefined) {
        var data = await getFromServer(session);
        console.log('in interactions array', data.data.datos);
        if (data.data.datos) {
            var intermediate = listToArray(data.data.datos, ['ei', 'i', 'v', 'g', 'trv', 'tfs'])
            console.log('AFTER LIST TO ARRAY', intermediate);
            return intermediate;
        }
        else {
            console.log("no data to return");
        }
    }
};


export default interactions_array;



