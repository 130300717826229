import React, { useState } from "react";
import {
  IconButton,
  Icon,
  Grid
} from "@material-ui/core";
import Widget from '../../components/Widget';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import { useTheme } from "@material-ui/styles";

import * as lp from 'linear-interpolator';

import PageTitle from "../../components/PageTitle";

import SessionIcons from './SessionIcons';

import interpolate from '../../utils/utils'

export default function Developer(props) {

  var points = interpolate({ x: 10, y: 10 }, { x: 100, y: 100 });
  console.log(points);
  return (
    <>
      <PageTitle title="Developer" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="heatmap">
            <p>Heatmap feature is under development, to access it, press here</p>
            <IconButton color="secondary" href="https://obsly.io/old/show.html" target="_blank">
              <WhatshotIcon />
            </IconButton>
          </Widget>
        </Grid>
      </Grid>

    </>
  );
}

// #######################################################################
