
const listToArray= (list,fields) => {
    if(list == undefined) return [];
    var theList=list.map((elm) => {
        var retobj= [];
        fields.forEach( (f) => retobj.push(elm[f]));
        return retobj;
    })

    // with header return [fields].concat(theList);
    return theList;
}

export default listToArray;