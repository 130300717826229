import {
    Chip,
    Grid, Avatar
  } from "@material-ui/core";

import './Tags.css';

export default function Tags() {
    return (<div className='tags'>
      <Chip size="medium" label='Accounts (4)' avatar={<Avatar>A</Avatar>} ></Chip>
      <Chip size="medium" label='Loan (1)' avatar={<Avatar>L</Avatar>} ></Chip>
      <Chip size="medium" label='Cards (2)' avatar={<Avatar>C</Avatar>} ></Chip>
    </div>)
  }