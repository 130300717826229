import React, { useCallback, useEffect, useState } from "react";
import "./FlowMap.css";

import VisNetworkReactComponent from "vis-network-react";
import getFromServer from "../DataComp/ViewsData";
import Button from '@material-ui/core/Button';
import CenterFocusStrong from '@material-ui/icons/CenterFocusStrong';

let datos = [{ "ei_first": "545084e0-a21b-4135-ae9b-182cc0fcb064", "elapsed_sum": 0.252, "t_max": 1616158591389, "t_min": 1616158587868, "v_": "EnvironmentActivity", "date_min": "2021-03-19T12:56:27.868Z", "date_max": "2021-03-19T12:56:31.389Z" }, { "ei_first": "6589d8e3-64cf-48f8-9d46-821daa8d65c1", "elapsed_sum": 0.106, "t_max": 1616158593226, "t_min": 1616158593226, "v_": "LoginActivity_", "date_min": "2021-03-19T12:56:31.389Z", "date_max": "2021-03-19T12:56:33.226Z" }, { "ei_first": "0a4cc42e-b5f5-4a99-8849-a92a6e392d8c", "elapsed_sum": 0.057, "t_max": 1616158594215, "t_min": 1616158594215, "v_": "ContextMenuActivity", "date_min": "2021-03-19T12:56:33.226Z", "date_max": "2021-03-19T12:56:34.215Z" }, { "ei_first": "c84a0857-c209-42f8-99b9-efab260a034e", "elapsed_sum": 0.617, "t_max": 1616160020125, "t_min": 1616158595350, "v_": "LoginActivity_", "date_min": "2021-03-19T12:56:34.215Z", "date_max": "2021-03-19T13:20:20.125Z" }, { "ei_first": "96b9af0f-aa8f-437a-bf61-8df5d52d642b", "elapsed_sum": 1.7970000000000002, "t_max": 1616160077293, "t_min": 1616160068202, "v_": "MainActivity_", "date_min": "2021-03-19T13:20:20.125Z", "date_max": "2021-03-19T13:21:17.293Z" }];


var imgUrl = "https://s3.eu-west-3.amazonaws.com/samuraiimages/";



function drawNodes(data) {
  var nodes = [], edges = [];
  var idx = 0;
  for (var key in data) {
    var elm = data[key];
    idx++;
    var node = {
      id: idx,
      shape: 'image',
      size: 15,
      label: elm["v"],
      image: imgUrl + elm["first_ei"] + ".png"
    }
    nodes.push(node);
    if (idx > 1) {
      var edge = {
        from: idx, to: idx - 1
      }
      edges.push(edge);
    }
  }
  return { nodes: nodes, edges: edges };
}
let defaultdata = drawNodes(datos);

let events = {
  click: function (params) {
    params.event = "[original event]";
    console.log(
      "click event, getNodeAt returns: " + this.getNodeAt(params.pointer.DOM)
    );
  },
  doubleClick: function (params) {
    console.log("doubleClick Event:", params);
    params.event = "[original event]";
  },
};
function FlowMap(props) {

  const options = {
    edges: {
      arrows: 'from',
      font: '10px roboto #ff0000',
      scaling: {
        label: true,
      },
      shadow: true,
      smooth: true,
    }
  }

  const [data, setData] = useState({});
  const [loading,setLoading] = useState(false);
  useEffect(() => {
    async function getData() {
      if (props.session != null) {
        var intermediate = await getFromServer(props.session)
        setData(drawNodes(intermediate.data.datos))
        setLoading(false)
      }
    }
    getData();
  }, [props.session]);

  const [visnet, setVisNet] = useState({});

  if (props.session == null) {
    return (<p>No session selected</p>);
  }
  if(loading) return (<h2>Loading</h2>)
  return (
    <div className="FlowMapParent">
      <div className="FlowMap">
        <VisNetworkReactComponent
          data={data}
          options={options}
          events={events}
          getNetwork={network => {
            setVisNet(network);
            network.fit();
          }}
        />
      </div>
      <div className="FlowMapFooter">
        <Button
          onClick={() => visnet.fit()}
          variant="contained"
          color="default"
          size="small"
          startIcon={<CenterFocusStrong />}
        >
          Center
     </Button>
      </div>
    </div>

  );
}

export default FlowMap;