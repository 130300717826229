import { Chart } from "react-google-charts";
import { useState, useEffect } from 'react';
import getFromServer from '../DataComp/ViewsData';

const convert = (data) => {
  console.log(data);
  var prevElement = null;
  for (var key in data) {
    var elm = data[key];
    elm["date_min"] = new Date(elm["mints"]);
    elm["date_max"] = new Date(elm["maxts"]);
    if (prevElement != null) {
      
      console.log(elm['v'],elm["date_min"],elm["date_max"])
      if (elm["date_min"] > prevElement["date_max"])
        elm["date_min"] = prevElement["date_max"];
    }
    prevElement = elm;
  }

  let rows = [];
  rows.push([
    { type: 'string', id: 'Position' },
    { type: 'string', id: 'Name' },
    { type: 'date', id: 'Start' },
    { type: 'date', id: 'End' }
  ]);

  for (var key1 in data) {
    var elmIn = data[key1];
    var theRow = ['View', elmIn.v, elmIn.date_min, elmIn.date_max];
    rows.push(theRow);
  }
  return rows;
};


function TimeLine(props) {
  const [data, setData] = useState([]);
  useEffect(() => {
    async function getData() {
      if (props.session != null) {
        var intermediate = await getFromServer(props.session);
        setData(convert(intermediate.data.datos));
      }
    }
    getData();
  }, [props.session])

  const chartEvents = [
    {
      eventName: "select",
      callback({ chartWrapper }) {
        console.log("Selected ", chartWrapper.getChart().getSelection());
      }
    }
  ];
  if (props.session == null) {
    return (<p>No session selected</p>);
  }
  return (
    <>
      <p></p>
      <Chart
        width='100%'
        height={100}
        chartType="Timeline"
        loader={<div>Loading Chart</div>}
        data={data}
        chartEvents={chartEvents}
        options={{
          title: 'Timeline'

        }}
        legendToggle
      />
    </>
  )
}

export default TimeLine;