import axios from 'axios';
import listToArray from '../ListToArray';
import getBaseUrl from '../ConfigMe';


const host = getBaseUrl();
const url = host + '/dosearch';
const token = localStorage.getItem('id_token');
const getFromServer = async () => {
    var axreturn = await axios.get(url,
        {
            searchtext: '',
            terms: [],
            headers: { 'obsly-token': token }
        }
    );
    console.log("in getFromServer", axreturn);
    return axreturn;
}



const sessions_array = async () => {
    return listToArray(await sessions_data(), ['mints', 'transitions', 'seconds', 'interactions', 'la', 'sr', 'av', 'si', 'd', 'np'])
};

const sessions_data = async () => {
    const data = await getFromServer();
    return data.data.datos;
}

export { sessions_array, sessions_data };
//console.log(listToArray(sessions,['an','d']));


