import {useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));


export default function AccordionWidget(props) {
    const classes = useStyles();
    const children = props.children;
    const [expanded, setExpanded] = useState(props.expanded);
    const handleChange = (panel) => (event,isExpanded) => {
        setExpanded(!expanded);
       
    }
    return (
        <div className={classes.root}>
            <Accordion  expanded={expanded} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h6" color="textSecondary" noWrap>
                        {props.title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>

                    {children}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
