export default function Requests() {
    return (<table>
        <thead>
            <tr>
                <th>Service</th>
                <th data-step="5">Volume</th>
                <th data-step="6">Success rate</th>
                <th data-step="7">Avg
              duration (ms)</th>
            </tr>
        </thead>
        <tbody>
            <tr class="active">
                <td><a href="#">api.imagin.com</a></td>
                <td>127</td>
                <td class="green">77.95%</td>
                <td>790</td>
            </tr>
            <tr>
                <td><a href="#">api.images.go</a></td>
                <td>106</td>
                <td class="green">83.02%</td>
                <td>400</td>
            </tr>
            <tr>
                <td><a href="#">api.samurai.com</a></td>
                <td>67</td>
                <td class="green">99.04%</td>
                <td>112</td>
            </tr>
            <tr>
                <td><a href="#">a.localytics.com</a></td>
                <td>62</td>
                <td class="green">12.87%</td>
                <td>200</td>
            </tr>

        </tbody>
    </table>)
}