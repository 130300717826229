import React from 'react';
import { Button } from '@material-ui/core'
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import { derivers as PivotDerivers } from 'react-pivottable/Utilities';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import './PivotTable.css';
import getFromServer from './PivotTableData';
// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

// see documentation for supported input formats

const derivedAttributes = {
    'mes_año': PivotDerivers.dateFormat("mints", '%y-%m'),
    'año': PivotDerivers.dateFormat('mints', '%y'),
    'duracion': PivotDerivers.bin('seconds', 60)
}
class PivotTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], loading: true, ...props };
        this.updateData();
        //this.timerID=setInterval(this.updateData,50000);
        this.save = () => {
            console.log('wants to save', this.state);
            var dataToSave = {
                rows: this.state.rows, cols: this.state.cols,
                rendererName: this.state.rendererName,
                aggregatorName: this.state.aggregatorName,
                vals: this.state.vals
            };
            localStorage.setItem(props.preferencesName, JSON.stringify(dataToSave));
        };
        this.restore = () => {
            console.log('restoring' + props.preferencesName);
            var retrievedObject = localStorage.getItem(props.preferencesName);
            var stateToRestore = JSON.parse(retrievedObject);
            this.setState(stateToRestore);
        }
    }
    updateData = async () => {
        let datos = await getFromServer();
        this.setState((prevState) => {
            return { data: datos.data.datos, loading: false }
        });
        this.restore();
    }
    componentWillUnmount() {
        //clearInterval(this.timerID);
    }
    //{this.state.data.length}<Button onClick={this.save} variant="primary">Save</Button>
    //<Button onClick={this.restore} variant="primary">Restore</Button>    
    render() {
        return (
            <>
                {this.state.loading ? <h2>Loading</h2> :
                    <PivotTableUI
                        onChange={s => { this.setState(s); this.save(); }}
                        derivedAttributes={derivedAttributes}
                        renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                        {...this.state}
                    />}
            </>
        );
    }
}

export default PivotTable;