import { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import getFromServer from './JobsErroredData';
import { Button } from '@material-ui/core';
let InitialData = {
  "datos": [
    {
      "log": "{\"trace\": [\"Traceback (most recent call last):\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/analyze/detect_pending.py\\\", line 124, in <module>\", \"    retval=analyze_session(session['si'])\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/analyze/analyze.py\\\", line 297, in analyze_session\", \"    derive = (df.v != df.v.shift()).cumsum()\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/dev/lib/python3.9/site-packages/pandas/core/generic.py\\\", line 5462, in __getattr__\", \"    return object.__getattribute__(self, name)\", \"AttributeError: 'DataFrame' object has no attribute 'v'\"]}",
      "si": "7bdfe7b9-3953-4c6b-a1c6-18700fae3dba",
      "timestamp": "2021-04-07 11:24:11.957646"
    },
    {
      "log": "{\"trace\": [\"Traceback (most recent call last):\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/analyze/detect_pending.py\\\", line 124, in <module>\", \"    retval=analyze_session(session['si'])\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/analyze/analyze.py\\\", line 297, in analyze_session\", \"    derive = (df.v != df.v.shift()).cumsum()\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/dev/lib/python3.9/site-packages/pandas/core/generic.py\\\", line 5462, in __getattr__\", \"    return object.__getattribute__(self, name)\", \"AttributeError: 'DataFrame' object has no attribute 'v'\"]}",
      "si": "8024ac98-04b4-4b60-8487-26cee2e455c7",
      "timestamp": "2021-04-07 11:24:19.831024"
    },
    {
      "log": "{\"trace\": [\"Traceback (most recent call last):\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/analyze/detect_pending.py\\\", line 124, in <module>\", \"    retval=analyze_session(session['si'])\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/analyze/analyze.py\\\", line 297, in analyze_session\", \"    derive = (df.v != df.v.shift()).cumsum()\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/dev/lib/python3.9/site-packages/pandas/core/generic.py\\\", line 5462, in __getattr__\", \"    return object.__getattribute__(self, name)\", \"AttributeError: 'DataFrame' object has no attribute 'v'\"]}",
      "si": "e0b73626-1111-4bd3-8b85-965d6170e376",
      "timestamp": "2021-04-07 11:25:58.552868"
    },
    {
      "log": "{\"trace\": [\"Traceback (most recent call last):\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/analyze/detect_pending.py\\\", line 124, in <module>\", \"    retval=analyze_session(session['si'])\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/analyze/analyze.py\\\", line 297, in analyze_session\", \"    derive = (df.v != df.v.shift()).cumsum()\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/dev/lib/python3.9/site-packages/pandas/core/generic.py\\\", line 5462, in __getattr__\", \"    return object.__getattribute__(self, name)\", \"AttributeError: 'DataFrame' object has no attribute 'v'\"]}",
      "si": "e0b73626-1111-4bd3-8b85-965d6170e381",
      "timestamp": "2021-04-07 11:26:00.337074"
    },
    {
      "log": "{\"trace\": [\"Traceback (most recent call last):\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/analyze/detect_pending.py\\\", line 124, in <module>\", \"    retval=analyze_session(session['si'])\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/analyze/analyze.py\\\", line 297, in analyze_session\", \"    derive = (df.v != df.v.shift()).cumsum()\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/dev/lib/python3.9/site-packages/pandas/core/generic.py\\\", line 5462, in __getattr__\", \"    return object.__getattribute__(self, name)\", \"AttributeError: 'DataFrame' object has no attribute 'v'\"]}",
      "si": "ef6e43f6-24c9-484c-86f3-271d315bbd3d",
      "timestamp": "2021-04-07 11:26:21.235002"
    },
    {
      "log": "{\"trace\": [\"Traceback (most recent call last):\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/analyze/detect_pending.py\\\", line 124, in <module>\", \"    retval=analyze_session(session['si'])\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/analyze/analyze.py\\\", line 297, in analyze_session\", \"    derive = (df.v != df.v.shift()).cumsum()\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/dev/lib/python3.9/site-packages/pandas/core/generic.py\\\", line 5462, in __getattr__\", \"    return object.__getattribute__(self, name)\", \"AttributeError: 'DataFrame' object has no attribute 'v'\"]}",
      "si": "fbbe993b-613d-44f3-8be0-31cbd462cc3a",
      "timestamp": "2021-04-07 11:26:42.548112"
    },
    {
      "log": "{\"trace\": [\"Traceback (most recent call last):\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/analyze/detect_pending.py\\\", line 124, in <module>\", \"    retval=analyze_session(session['si'])\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/analyze/analyze.py\\\", line 297, in analyze_session\", \"    derive = (df.v != df.v.shift()).cumsum()\", \"  File \\\"/Volumes/PROYECTOS/samuraiv2/dev/lib/python3.9/site-packages/pandas/core/generic.py\\\", line 5462, in __getattr__\", \"    return object.__getattribute__(self, name)\", \"AttributeError: 'DataFrame' object has no attribute 'v'\"]}",
      "si": "66e86077-452b-4937-a558-c4b73e9c045e",
      "timestamp": "2021-04-07 11:28:36.147378"
    }]
};
const JobsErrored = (props) => {
  const [data, setData] = useState(InitialData.datos);
  const columns = [
    {
      name: 'si', label: 'Session Id', options: {
        filter: false,
        sort: true,
        display: true
      }
    },
    {
      name: 'timestamp', label: 'Time', options: {
        filter: false,
        sort: true,
      }
    }, "log"];
    

  const options = {
    selectableRows: 'single',
    print: false,
    download: false,
    tableBodyHeight: '60vh',
    filterType: 'dropdown',
    elevation: 2,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return <Button onClick={() => { setSelectedRows([]) }}>Clear</Button>
    },
  };
  const getData = async () => { var d=await getFromServer();
    console.log(d);
    setData(d.data.datos); };

  useEffect((data) => {

    if (!data) {
      getData();
    }
  }, []);

  return (
    <>
      <MUIDataTable
        title={"Jobs Errored"}
        data={data}
        columns={columns}
        options={options}
      />
    </>
  );
};

export default JobsErrored;
