import React, { useState } from "react";
import {
  Grid
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from './styles';

// components


import PageTitle from "../../components/PageTitle/PageTitle";
import PivotTable from '../../samurai/PivotTableViews/PivotTable';
import Widget from "../../components/Widget/Widget";

export default function Views(props) {
  var classes = useStyles();
  var theme = useTheme();
  

  
  return (
    <>
      <PageTitle title="Views analysis" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
        <Widget>
        <PivotTable preferencesName='views01'/>    
        </Widget>
        </Grid>
      </Grid>
      
    </>
  );
}

// #######################################################################
