import { useState, useEffect } from 'react';

import MUIDataTable from 'mui-datatables';
import { sessions_array, sessions_data } from './SessionData';
import { Button, LinearProgress } from '@material-ui/core';
import './Sessions.css';
import SessionIcons from './Components/SessionIcons';

function Sessions(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowSelected, setRowSelected] = useState(null);
  const options = {
    elevation: 2,
    print: false,
    download: false,
    tableBodyHeight: '70vh',
    selectableRows: 'single',
    filterType: 'dropdown',
    responsive: 'vertical',
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return <Button onClick={() => { setSelectedRows([]) }}>Clear</Button>
    },
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      setRowSelected(rowsSelected);
      if (props.onSelection) {
        if (rowsSelected.length > 0)
          props.onSelection(data[rowsSelected]);
        else
          props.onSelection(null);
      }
    },
  };
  const getData = async () => {
    try {
      var data = await sessions_data()
      setData(data);
      setLoading(false);
    }
    catch {

    } finally {

    }
  }

  useEffect(() => {
    getData();
  }, []);
  const columns = [
    'an',
    {
      name: "mints", label: 'Hour', options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          var fecha = data[dataIndex]['mints'];
          try {
            var d = new Date(fecha);
            fecha = d.toLocaleString();
          } catch {
            console.log('failed')
          }
          return fecha;
        },

        setCellProps: () => ({ style: { textAlign: 'right' } })
      }
    }, {
      name: "transitions", options: {
        label: 'Views',
        filter: false,
        setCellHeaderProps: () => ({ className: 'rightHeader' }),
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!value) {
            return (<></>)
          }
          let tpc = Math.min(20, value / 20) * 100;
          return (
            <>
              {value}
              <LinearProgress variant="determinate" value={tpc} />
            </>);
        },
        setCellProps: () => ({ style: { textAlign: 'right' } })
      }
    },
    {
      name: "seconds", label: 'Seconds', options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!value) {
            return (<></>)
          }
          let tpc = Math.min(100, value / 300 * 100);
          return (
            <>
              {value}
              <LinearProgress variant="determinate" value={tpc} />
            </>);
        },
        setCellProps: () => ({ style: { textAlign: 'right' } })
      }
    },
    { name: 'la', label: 'Language' }, { name: 'sr', label: 'Resolution' }
    , { name: 'av', label: 'Version' }, {
      name: 'si', label: 'Si', options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'd', label: 'Device', options: {
        filter: true,
        sort: true,
      }
    }, { name: 'np', label: 'Customer' },
    {
      name: "interactions",
      label: 'Interactions',
      options: {
        filter: false,

        setCellProps: () => ({ style: { textAlign: 'right' } })
      }
    }, {
      name: 'hasRequest', label: 'Requests', options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!value) {
            return (<></>)
          }
          return (
            <SessionIcons Requests={value} />)
        }
      }
    },
    {
      name: 'hasTags', label: 'Tags', options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!value) {
            return (<></>)
          }
          return (
            <SessionIcons Label={value} />)
        }
      }
    },
    {
      name: 'hasCrash', label: 'Crash', options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!value) {
            return (<></>)
          }
          return (<SessionIcons Error={value} />)
        }
      }
    }


  ];
  return (
    <>
      {loading ? <h2>Loading</h2> :
        <MUIDataTable
          title={"Sessions"}
          data={data}
          columns={columns}
          options={options}
        />}
    </>
  );
}

export default Sessions;
