import {
    LocalOffer, BugReport, Warning,
    Error as ErrorIcon, Videocam
  } from "@material-ui/icons";
  import {
    Icon,
    Grid
  } from "@material-ui/core";
  
import './SessionIcons.css';
export default function SessionIcons(props) {

    var lo=props.Label, lo2=props.Error,lo3 = false,lo4=props.Requests,lo5=props.Video;
    let icon1= lo ?<LocalOffer className='green'/> : null;
    let icon2 = lo2 ? <BugReport className='red'/> : null;
    let icon3 = lo3 ? <ErrorIcon className='blue'/> : null;
    let icon4 = lo4 ? <Warning className='orange'/> : null;
    let icon5 = lo5 ? <Videocam className='green'/> : null;
    return (<>
      {icon1}
      {icon2}  
      {icon3}
      {icon4}
      {icon5}
    </>)
}