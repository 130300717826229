import axios from 'axios';
import getBaseUrl from '../ConfigMe';

const host = getBaseUrl();
const url = host+'/viewsbysi';
const token = localStorage.getItem('id_token');


const getFromServer = async (session) => {
  var axreturn = await axios.get(url + '/' + session,{ headers: { 'obsly-token': token }});
  return axreturn;
}

export default getFromServer