import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'



// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages

import Samurai from '../../pages/SessionDetails/SessionDetails';

import SessionDivided from '../../pages/SessionDivided/SessionDivided';
import SessionAnalysis from '../../pages/SessionAnalysis';
import ViewsAnalysis from '../../pages/ViewsAnalysis';
import InteractionsAnalysis from '../../pages/InteractionsAnalysis';
import Developer from '../../pages/developer';
import Jobs from '../../pages/jobs';
// context
import { useLayoutState } from "../../context/LayoutContext";


function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/samurai" component={SessionDivided} />
              <Route path="/app/sessiondivided" component={SessionDivided} />
              <Route path="/app/sessionanalysis" component={SessionAnalysis} />
              <Route path="/app/viewsanalysis" component={ViewsAnalysis} />
              <Route path="/app/interactionsanalysis" component={InteractionsAnalysis} />
              <Route path="/app/developer" component={Developer} />
              <Route path="/app/jobs" component={Jobs} />
            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
           
              <div>
  
              </div>
            </Box>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
